import React from 'react';
import HeaderSection from '../Components/LandingPage/HeadSection';
import ServiceSection from '../Components/LandingPage/ServiceSection';
// import HowItWorksSection from '../Components/LandingPage/HowItWorksSection';
import MenuSection from '../Components/LandingPage/MenuSection';
import Testimonial from "../Components/LandingPage/TestimonialSection";
import CallbackForm from "../Components/LandingPage/CallBackForm";
const TiffinService = () => {
    return (
        <>
            <HeaderSection />
            <ServiceSection />
            <MenuSection />
            <Testimonial/>
<CallbackForm/>
            </>
    );
};

export default TiffinService;