import React, { useState } from 'react';
import Switch from 'react-switch';
import styled from 'styled-components';
import LunchIcon from '../LandingPage/Lunch_Icon.svg'; // Replace with the path to your Lunch SVG
import DinnerIcon from '../LandingPage/Dinner_Icon.svg'; // Replace with the path to your Dinner SVG

const MenuSectionContainer = styled.div`
    padding: 4rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin-left: 3rem; /* Add left margin for larger screens */

    @media (max-width: 768px) {
        margin-left: 0; /* Remove left margin on mobile */
        padding: 2rem 1rem;
    }
`;

const MenuHeaderContainer = styled.div`
    width: 100%;
    text-align: left;

    @media (max-width: 768px) {
        text-align: center; /* Center header content on mobile */
    }
`;

const MenuTitle = styled.h2`
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    color: #6F1D3B;
    text-transform: uppercase;

    @media (max-width: 768px) {
        text-align: center;
    }
`;

const MenuSubtitle = styled.h3`
    font-size: 2rem;
    margin-bottom: 1rem;
    font-weight: bold;
    color: #333;

    @media (max-width: 768px) {
        text-align: center;
    }
`;

const ControlsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start; /* Align items to the left on larger screens */
    gap: 2rem;
    margin-bottom: 3rem; /* Add spacing after toggle switches */
    width: 100%; /* Ensure the container spans the full width */

    @media (max-width: 768px) {
        justify-content: center; /* Center switches on mobile */
    }
`;

const SwitchContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
`;

const SwitchLabel = styled.span`
    font-size: 1rem;
    font-weight: bold;
    color: ${props => (props.active ? (props.type === 'veg' ? '#44B244' : '#EA4335') : '#333')};
`;

const MenuContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center; /* Vertically center the tabs */
    justify-content: flex-start; /* Align the content to the left */
    width: 100%;
    max-width: 1200px;
    gap: 2rem;

    @media (max-width: 768px) {
        flex-direction: column; /* Stack content on mobile */
        align-items: center; /* Center content on mobile */
    }
`;

const MenuTabs = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start; /* Align the tabs to the left */
    justify-content: center; /* Vertically center the tabs */
    width: 20%; /* Adjust width as needed to balance layout */
    height: 100%; /* Take up full height of the parent container */

    @media (max-width: 768px) {
        width: 100%; /* Full width on mobile */
        align-items: center; /* Center tabs on mobile */
    }
`;

const MenuTab = styled.button`
    padding: 1rem 2rem;
    border: none;
    border-radius: 2rem;
    background-color: ${props => (props.active ? '#6F1D3B' : '#f0f0f0')};
    color: ${props => (props.active ? '#fff' : '#333')};
    font-size: 1.25rem;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    transition: background-color 0.3s;
    display: flex;
    align-items: center;
    gap: 0.5rem;

    img {
        width: 24px;
        height: 24px;
    }

    &:hover {
        background-color: ${props => (props.active ? '#57202F' : '#e0e0e0')};
    }

    @media (max-width: 768px) {
        width: 100%; /* Full width on mobile */
        justify-content: center; /* Center content on mobile */
    }
`;

const MenuItemsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 2rem;
    box-sizing: border-box;

    @media (max-width: 768px) {
        align-items: center; /* Center items on mobile */
    }
`;

const FullWidthImage = styled.img`
    width: 100%;
    height: auto;
    max-height: 400px;
    object-fit: cover;
    border-radius: 1rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    @media (max-width: 768px) {
        max-height: 300px;
    }
`;

const UpdatedMenuSection = () => {
    const [vegSelected, setVegSelected] = useState(true);
    const [activeTab, setActiveTab] = useState('Lunch');

    // Placeholder content for Veg/Non-Veg
    const placeholderImages = {
        Veg: './veg_menu.png',
        NonVeg: './non_veg_menu.png',
    };

    const handleVegChange = (checked) => {
        setVegSelected(checked);
    };

    const handleNonVegChange = (checked) => {
        setVegSelected(!checked);
    };

    return (
        <MenuSectionContainer>
            <MenuHeaderContainer>
                <MenuTitle>Our Menu</MenuTitle>
                <MenuSubtitle>Menu That Always Makes You Fall In Love</MenuSubtitle>
            </MenuHeaderContainer>
            <ControlsContainer>
                <SwitchContainer>
                    <SwitchLabel active={vegSelected} type="veg">
                        Veg
                    </SwitchLabel>
                    <Switch
                        onChange={handleVegChange}
                        checked={vegSelected}
                        onColor="#44B244"
                        offColor="#f0f0f0"
                        checkedIcon={false}
                        uncheckedIcon={false}
                        height={24}
                        width={48}
                    />
                </SwitchContainer>
                <SwitchContainer>
                    <SwitchLabel active={!vegSelected} type="nonveg">
                        Non-Veg
                    </SwitchLabel>
                    <Switch
                        onChange={handleNonVegChange}
                        checked={!vegSelected}
                        onColor="#EA4335"
                        offColor="#f0f0f0"
                        checkedIcon={false}
                        uncheckedIcon={false}
                        height={24}
                        width={48}
                    />
                </SwitchContainer>
            </ControlsContainer>
            <MenuContainer>
                <MenuTabs>
                    <MenuTab active={activeTab === 'Lunch'} onClick={() => setActiveTab('Lunch')}>
                        <img src={LunchIcon} alt="Lunch Icon" />
                        Lunch
                    </MenuTab>
                    <MenuTab active={activeTab === 'Dinner'} onClick={() => setActiveTab('Dinner')}>
                        <img src={DinnerIcon} alt="Dinner Icon" />
                        Dinner
                    </MenuTab>
                </MenuTabs>
                <MenuItemsContainer>
                    <FullWidthImage
                        src={vegSelected ? placeholderImages.Veg : placeholderImages.NonVeg}
                        alt={vegSelected ? 'Veg Menu' : 'Non-Veg Menu'}
                    />
                </MenuItemsContainer>
            </MenuContainer>
        </MenuSectionContainer>
    );
};

export default UpdatedMenuSection;
