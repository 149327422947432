import React from 'react';
import styled from 'styled-components';

const ServiceSectionContainer = styled.div`
    padding:  2rem;
    text-align: center;
`;

const SectionSubtitle = styled.p`
    font-size: 1.25rem;
    color: #6F1D3B;
    margin-bottom: 0.5rem;
    font-weight: 700;
`;

const SectionTitle = styled.h2`
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1rem;

    @media (max-width: 768px) {
        font-size: 2rem;
    }
`;

const ServiceCards = styled.div`
    display: flex;
    justify-content: space-around;
    margin-top: 2rem;
    gap: 2rem;
    flex-wrap: wrap; /* Enables wrapping of cards */

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        gap: 2rem;
    }
`;

const ServiceCard = styled.div`
    max-width: 30%;
    text-align: center;

    @media (max-width: 768px) {
        max-width: 80%; /* Adjust width for smaller screens */
    }
`;

const ServiceImage = styled.img`
    width: 8rem;
    height: auto;
    margin-bottom: 1rem;

    @media (max-width: 768px) {
        width: 6rem; /* Reduce size for smaller screens */
    }
`;

const ServiceTitle = styled.h3`
    font-size: 1.75rem;
    font-weight: bold;
    margin-bottom: 0.5rem;

    @media (max-width: 768px) {
        font-size: 1.5rem;
    }
`;

const ServiceDescription = styled.p`
    font-size: 1.25rem;

    @media (max-width: 768px) {
        font-size: 1rem;
    }
`;

const ServiceSection = () => {
    return (
        <ServiceSectionContainer>
            <SectionSubtitle>WHY CHOOSE US</SectionSubtitle>
            <SectionTitle>Your Favorite Tiffin Service Partner</SectionTitle>
            <ServiceCards>
                <ServiceCard>
                    <ServiceImage src="./Delivery.svg" alt="Easy To Order" />
                    <ServiceTitle>Easy To Order</ServiceTitle>
                    <ServiceDescription>You only need a few steps in ordering food</ServiceDescription>
                </ServiceCard>
                <ServiceCard>
                    <ServiceImage src="./Convenient.svg" alt="Convenient" />
                    <ServiceTitle>Convenient</ServiceTitle>
                    <ServiceDescription>Cancel or add anytime</ServiceDescription>
                </ServiceCard>
                <ServiceCard>
                    <ServiceImage src="./Best_Qualtiy.svg" alt="Best Quality" />
                    <ServiceTitle>Best Quality</ServiceTitle>
                    <ServiceDescription>
                        Not only fast for us, quality is also number one
                    </ServiceDescription>
                </ServiceCard>
            </ServiceCards>
        </ServiceSectionContainer>
    );
};

export default ServiceSection;
