import React, { useState } from "react";
import styled from "styled-components";
import { useSwipeable } from "react-swipeable";

const TestimonialSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 4rem 2rem;
    background-color: #fff;
    position: relative;
    overflow: hidden;

    @media (min-width: 768px) {
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
        padding: 6rem 4rem;
    }
`;

const CarouselContainer = styled.div`
    display: flex;
    transition: transform 0.5s ease-in-out;
    transform: translateX(${props => props.translateValue}%);
    width: 100%;
`;

const TestimonialSlideDesktop = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;

    @media (max-width: 768px) {
        display: none; /* Hide desktop layout on mobile */
    }
`;

const TestimonialSlideMobile = styled.div`
    display: none; /* Hide mobile layout on desktop */

    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 100%;
    }
`;

const TextContainer = styled.div`
    flex: 1;
    padding: 2rem;
    text-align: center;

    @media (min-width: 768px) {
        text-align: left;
    }
`;

const SectionSubtitle = styled.p`
    font-size: 1.25rem;
    color: #6F1D3B;
    margin-bottom: 0.5rem;
    font-weight: bold;
    text-transform: uppercase;
`;

const SectionTitle = styled.h2`
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1.5rem;

    @media (min-width: 768px) {
        font-size: 3rem;
    }
`;

const ImageContainer = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 90%;
        max-width: 400px;
        height: auto;
        border-radius: 1rem;
        object-fit: cover;

        @media (min-width: 768px) {
            width: 100%;
            max-width: 500px;
        }
    }
`;

const Quote = styled.p`
    font-size: 0.75rem;
    color: #333;
    margin-bottom: 2rem;
    line-height: 1.6;

    @media (min-width: 768px) {
        font-size: 1.5rem;
    }
`;

const CustomerInfo = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;

    img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        object-fit: cover;
    }
`;

const CustomerName = styled.div`
    display: flex;
    flex-direction: column;

    span {
        font-size: 1rem;
        font-weight: bold;
        color: #333;
    }

    small {
        font-size: 0.875rem;
        color: #777;
    }
`;

const Rating = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;

    .stars {
        font-size: 1.5rem;
        color: #FFD700;
    }

    .rating-value {
        font-size: 1.25rem;
        color: #333;
    }
`;

const DotsContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    margin-top: 1rem;
`;

const Dot = styled.div`
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${props => (props.isActive ? "#6F1D3B" : "#ddd")};
    transition: background-color 0.3s;
    cursor: pointer;
`;

const NavigationContainer = styled.div`
    position: absolute;
    bottom: 2rem;
    right: 2rem;

    @media (min-width: 768px) {
        bottom: 3rem;
        right: 3rem;
    }

    @media (max-width: 768px) {
        bottom: 1rem;
        right: 1rem;
    }
`;

const Arrow = styled.button`
    background-color: #6F1D3B;
    color: #fff;
    border: none;
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: #57202F;
    }
`;

const testimonials = [
    {
        img: "./Testimonial.png",
        quote: "DabbaGO is the best. Besides the many and delicious meals, the service is also very good, especially in the very fast delivery. I highly recommend DabbaGO to you.",
        name: "Anirudh",
        role: "Food Enthusiast",
        rating: 5,
    },
    {
        img: "./Testimonial_2.png",
        quote: "Dabbago has transformed my mealtime! As a busy professional, I finally have access to fresh, delicious, home-style meals delivered right to my door. Each dish is nutritious, flavorful, and convenient—perfect for anyone craving healthy, hassle-free dining. Highly recommend!",
        name: "Anusha",
        role: "Food Enthusiast",
        rating: 5,
    },
];

const Testimonial = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
    };

    const handlePrev = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
        );
    };

    const handleDotClick = (index) => {
        setCurrentIndex(index);
    };

    const handlers = useSwipeable({
        onSwipedLeft: () => handleNext(),
        onSwipedRight: () => handlePrev(),
    });

    return (
        <TestimonialSection {...handlers}>
            <CarouselContainer translateValue={-currentIndex * 100}>
                {/* Desktop Layout */}
                {testimonials.map((testimonial, index) => (
                    <TestimonialSlideDesktop key={`desktop-${index}`}>
                        <ImageContainer>
                            <img src={testimonial.img} alt={testimonial.name} />
                        </ImageContainer>
                        <TextContainer>
                            <SectionSubtitle>What They Say</SectionSubtitle>
                            <SectionTitle>What Our Customers Say About Us</SectionTitle>
                            <Quote>{testimonial.quote}</Quote>
                            <CustomerInfo>
                                <img src={testimonial.img} alt={testimonial.name} />
                                <CustomerName>
                                    <span>{testimonial.name}</span>
                                    <small>{testimonial.role}</small>
                                </CustomerName>
                            </CustomerInfo>
                            <Rating>
                                <div className="stars">
                                    {Array(Math.round(testimonial.rating))
                                        .fill("⭐")
                                        .join("")}
                                </div>
                                <div className="rating-value">{testimonial.rating}</div>
                            </Rating>
                        </TextContainer>
                    </TestimonialSlideDesktop>
                ))}

                {/* Mobile Layout */}
                {testimonials.map((testimonial, index) => (
                    <TestimonialSlideMobile key={`mobile-${index}`}>
                        <TextContainer>
                            <SectionSubtitle>What They Say</SectionSubtitle>
                            <SectionTitle>What Our Customers Say About Us</SectionTitle>
                        </TextContainer>
                        <ImageContainer>
                            <img src={testimonial.img} alt={testimonial.name} />
                        </ImageContainer>
                        <TextContainer>
                            <Quote>{testimonial.quote}</Quote>
                            <CustomerInfo>
                                <img src={testimonial.img} alt={testimonial.name} />
                                <CustomerName>
                                    <span>{testimonial.name}</span>
                                    <small>{testimonial.role}</small>
                                </CustomerName>
                            </CustomerInfo>
                            <Rating>
                                <div className="stars">
                                    {Array(Math.round(testimonial.rating))
                                        .fill("⭐")
                                        .join("")}
                                </div>
                                <div className="rating-value">{testimonial.rating}</div>
                            </Rating>
                        </TextContainer>
                    </TestimonialSlideMobile>
                ))}
            </CarouselContainer>

            {/* Dots for navigation */}
            <DotsContainer>
                {testimonials.map((_, index) => (
                    <Dot
                        key={index}
                        isActive={index === currentIndex}
                        onClick={() => handleDotClick(index)}
                    />
                ))}
            </DotsContainer>

            {/* Arrows for navigation */}
            <NavigationContainer>
                {/*<Arrow onClick={handlePrev}>&#8592;</Arrow>*/}
                <Arrow onClick={handleNext}>&#8594;</Arrow>
            </NavigationContainer>
        </TestimonialSection>
    );
};

export default Testimonial;
