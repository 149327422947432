import logo from './logo.svg';
import './App.css';
import LandingPage from "./Pages/LandingPage";
import { createGlobalStyle } from 'styled-components';
const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');
  
  body {
    font-family: 'Poppins', sans-serif;
  }
`;
function App() {


    return (
    <div className="App">
        <GlobalStyle/>
<LandingPage/>
    </div>
  );
}

export default App;
