import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: column; /* Default for mobile */
    align-items: center;
    padding: 2rem;
    background-color: #fff;
    font-family: 'Poppins', sans-serif;
    height: auto;
    box-sizing: border-box;
    position: relative;

    @media (min-width: 768px) {
        flex-direction: row-reverse; /* Restore row for desktop */
        justify-content: space-between;
        height: 100vh;
    }
`;

const LogoContainer = styled.div`
    position: absolute;
    top: 1rem;
    left: 1rem;
    z-index: 10;
    margin-bottom: 2rem; /* Default margin */

    @media (max-width: 767px) {
        position: relative; /* Make it part of the document flow */
        margin-bottom: 3rem; /* Extra margin for mobile devices */
        left: auto;
        top: auto;
    }

    @media (min-width: 768px) {
        top: 2rem;
        left: 2rem;
        margin-bottom: 3rem; /* Increased margin for desktop */
    }
`;

const Logo = styled.img`
    width: 6rem;
    height: auto;

    @media (min-width: 768px) {
        width: 8rem;
    }

    @media (min-width: 1024px) {
        width: 10rem;
    }
`;

const ContentContainer = styled.div`
    width: 100%;
    max-width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    //margin-top: 10rem; /* Ensure spacing for mobile below logo */
    padding-top: 2rem; /* Additional padding for better spacing */

    @media (min-width: 768px) {
        margin-top: 4rem; /* Adjust margin for desktop */
        max-width: 40%;
        height: 100%;
    }
`;

const Header = styled.h1`
    font-size: 2rem;
    font-weight: bold;
    color: #333333;
    margin-bottom: 1rem;
    text-align: center;

    @media (min-width: 768px) {
        font-size: 3rem;
    }

    @media (min-width: 1024px) {
        font-size: 4rem;
    }
`;

const SubHeader = styled.p`
    font-size: 1rem;
    margin-bottom: 2rem;
    text-align: center;
    color: #333333;

    @media (min-width: 768px) {
        font-size: 1.25rem;
    }

    @media (min-width: 1024px) {
        font-size: 1.5rem;
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 2rem;

    @media (min-width: 768px) {
        flex-direction: row;
        justify-content: center;
    }
`;

const Button = styled.button`
    padding: 1rem 2rem;
    border: none;
    border-radius: 2rem;
    background-color: #6f1d3b;
    color: #fff;
    font-size: 1rem;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: #ff5722;
    }

    @media (min-width: 768px) {
        font-size: 1.25rem;
    }
`;

const WatchButton = styled(Button)`
    background-color: #f0f0f0;
    color: #333;

    &:hover {
        background-color: #e0e0e0;
    }
`;

const ImageContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;

    @media (min-width: 768px) {
        max-width: 60%;
        height: 100%;
        margin-top: 0;
    }
`;

const Illustration = styled.img`
    width: 100%;
    max-width: 400px;
    height: auto;

    @media (min-width: 768px) {
        max-width: 90%;
    }
`;

const CustomerInfo = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;

    img {
        width: 100%;
        height: 50%;
        border-radius: 50%;
        object-fit: cover;
    }
`;

const CustomerName = styled.div`
    display: flex;
    flex-direction: column;

    span {
        font-size: 1rem;
        font-weight: bold;
        color: #333;
    }

    small {
        font-size: 0.875rem;
        color: #777;
    }
`;

const Rating = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;

    .stars {
        font-size: 1.5rem;
        color: #FFD700;
    }

    .rating-value {
        font-size: 1.25rem;
        color: #333;
    }
`;

const HeaderSection = () => {
    const handleGetStartedClick = () => {
        window.location.href = 'https://devaj.typeform.com/to/LlZOh2'; // Navigate in the same tab
    };


    return (
        <Container>
            <LogoContainer>
                <Logo src="./DBG_Logo.svg" alt="Dabbago Logo" />
            </LogoContainer>
            <ImageContainer>
                <Illustration src="./HeaderImage2.png" alt="Tiffin Service Illustration" />
            </ImageContainer>
            <ContentContainer>
                <Header>Your Go-TO <br /> Tiffin Service</Header>
                <SubHeader>
                    Our job is to fill your tummy with delicious food and with fast and free delivery
                </SubHeader>
                <ButtonContainer>
                    <Button onClick={handleGetStartedClick}>Get Started</Button>
                    <WatchButton>Watch Video</WatchButton>
                </ButtonContainer>
                <CustomerInfo>
                    <img src="./Customers.png" alt="Anusha" />
                </CustomerInfo>
            </ContentContainer>
        </Container>
    );
};

export default HeaderSection;
