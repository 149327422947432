import React, { useState } from 'react';
import styled from 'styled-components';

// Styled components
const FormSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 4rem 2rem;
    background-color: #fff;

    @media (min-width: 768px) {
        flex-direction: row;
        justify-content: space-between;
        padding: 6rem 6rem;
    }

    @media (min-width: 1200px) {
        padding: 6rem 8rem;
    }
`;

const InfoContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    @media (min-width: 768px) {
        align-items: flex-start;
        text-align: left;
    }
`;

const Logo = styled.img`
    width: 150px;
    height: auto;
    margin-bottom: 1rem;
`;

const InfoText = styled.p`
    font-size: 1.25rem;
    color: #333;
    margin-bottom: 2rem;
    line-height: 1.6;
`;

const SocialIcons = styled.div`
    display: flex;
    gap: 1rem;

    a {
        font-size: 1.5rem;
        color: #6F1D3B;
        text-decoration: none;
        transition: color 0.3s;

        &:hover {
            color: #ff7043;
        }
    }
`;

const FormContainer = styled.div`
    flex: 1;
    background-color: #6F1D3B;
    padding: 2rem;
    border-radius: 1rem;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);

    @media (min-width: 768px) {
        max-width: 500px;
        margin-left: 2rem;
    }
`;

const FormTitle = styled.h3`
    font-size: 1.5rem;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 2rem;
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
`;

const Input = styled.input`
    padding: 1rem;
    font-size: 1rem;
    border: 2px solid #fff;
    border-radius: 0.5rem;
    background: transparent;
    color: #fff;
    outline: none;

    &::placeholder {
        color: #ddd;
    }
`;

const SubmitButton = styled.button`
    padding: 1rem;
    font-size: 1.25rem;
    font-weight: bold;
    color: #fff;
    background-color: #ff7043;
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: background-color 0.3s, box-shadow 0.3s;

    &:hover {
        background-color: #e65a2e;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }
`;

const Snackbar = styled.div`
    position: fixed;
    top: 2rem;
    left: 50%;
    transform: translateX(-50%);
    background-color: ${props => (props.error ? '#EA4335' : '#44B244')};
    color: white;
    padding: 1rem 2rem;
    border-radius: 0.5rem;
    font-size: 1rem;
    font-weight: bold;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    animation: fadeInOut 4s ease-in-out forwards;

    @keyframes fadeInOut {
        0% { opacity: 0; transform: translate(-50%, 50px); }
        10%, 90% { opacity: 1; transform: translate(-50%, 0); }
        100% { opacity: 0; transform: translate(-50%, 50px); }
    }
`;

const CallbackForm = () => {
    const [formData, setFormData] = useState({ name: '', phone: '', email: '' });
    const [snackbar, setSnackbar] = useState({ message: '', error: false, visible: false });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { name, phone, email } = formData;

        if (!name || !phone || !email) {
            setSnackbar({ message: 'Please fill all the fields.', error: true, visible: true });
            setTimeout(() => setSnackbar({ ...snackbar, visible: false }), 4000);
            return;
        }

        try {
            const response = await fetch('https://erp.fooddarzee.com/dabba/api/callBack', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                },
                body: new URLSearchParams({
                    name,
                    phone,
                    email,
                }),
            });

            if (response.ok) {
                setSnackbar({ message: 'Form submitted successfully!', error: false, visible: true });
                setFormData({ name: '', phone: '', email: '' }); // Reset form
            } else {
                setSnackbar({ message: 'Failed to submit form. Please try again.', error: true, visible: true });
            }
        } catch (error) {
            setSnackbar({ message: 'An error occurred while submitting the form.', error: true, visible: true });
            console.error('Error:', error);
        } finally {
            setTimeout(() => setSnackbar({ ...snackbar, visible: false }), 4000); // Hide snackbar after 4 seconds
        }
    };

    return (
        <FormSection>
            <InfoContainer>
                <Logo src="./DBG_Logo.svg" alt="Dabba Go Logo" />
                <InfoText>
                    Our job is to fill <br /> your tummy with <br /> delicious food and with <br /> fast and free delivery.
                </InfoText>
                <SocialIcons>
                    <a href="#"><i className="fab fa-instagram"></i></a>
                    <a href="#"><i className="fab fa-facebook-f"></i></a>
                    <a href="#"><i className="fab fa-twitter"></i></a>
                </SocialIcons>
            </InfoContainer>
            <FormContainer>
                <FormTitle>Submit Form to Get Started</FormTitle>
                <Form onSubmit={handleSubmit}>
                    <Input
                        type="text"
                        placeholder="Full Name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />
                    <Input
                        type="tel"
                        placeholder="Phone Number"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        required
                    />
                    <Input
                        type="email"
                        placeholder="Email Address"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                    <SubmitButton type="submit">Request a Callback</SubmitButton>
                </Form>
            </FormContainer>
            {snackbar.visible && (
                <Snackbar error={snackbar.error}>
                    {snackbar.message}
                </Snackbar>
            )}
        </FormSection>
    );
};

export default CallbackForm;
